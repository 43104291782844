<template>
  <div class="h-full w-screen">
    <LoadingState v-if="isLoading" />
    <Container>
      <GlofoxPortal :source="timetableSource" @loaded="isLoading=false"/>
    </Container>
  </div>
</template>

<script>

import { GlofoxPortal, LoadingState } from '@/pages/shared/index';
import Container from '@/components/atoms/Frames/Container.vue';

export default {
  name: 'GlofoxPage',
  components: {
    Container,
    GlofoxPortal,
    LoadingState
  },
  data: () => ({
    isLoading: false,
    timetableSource: `https://app.glofox.com/portal/#/branch/${process.env.VUE_APP_GLOFOX_TOKEN}/classes-day-view?header=classes`
  })
};
</script>
